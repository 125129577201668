import {ContentChild, Directive, ElementRef, HostListener, Input, Renderer2, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appTooltip]',
  host: {
    '[style.position]': '"relative"'
  }
})
export class TooltipDirective {
  private tooltipId: string;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) { }

  @Input() templateRef: TemplateRef<any>;
  @ContentChild('tooltipTemplate') private tooltipTemplateRef: TemplateRef<object>;
  @HostListener('mouseenter') onMouseEnter(): void {
    const view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
    view.rootNodes.forEach(node => {
      this.renderer.appendChild(this.el.nativeElement, node);
    })
  }
  
  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }
  
}
